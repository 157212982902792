.vh-100 {
    height: 100vh;
}

.w-48 {
    width: 48%;
}

.w-75 {
    width: 75%;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.p-0 {
    padding: 0;
}

.carousel img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel_border {
    border-radius: 10px;
    min-height: 300px;
}

.card-view {
    border: 1px solid #696969;
    border-radius: 10px;
    padding: 20px;
    height: 100%;
}

.left-label {
    text-align: left;
    display: block;
    margin-bottom: 0.5rem;
    padding: 5px;
}

.reg-img{
    width: auto;
    max-height: 70vh;
}

.card-view-register-mobile{
    border-radius: 10px;
    padding: 5% 5%;
    background: rgba(129, 126, 126, 0.5);
    min-height: 70vh;
}
