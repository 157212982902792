.vh-100 {
    height: 100vh;
}

.w-48 {
    width: 48%;
}

.w-75 {
    width: 75%;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.p-0 {
    padding: 0;
}

.carousel img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel_border {
    border-radius: 10px;
    min-height: 300px;
}

.left-label {
    text-align: left;
    display: block;
    margin-bottom: 0.5rem;
    padding: 5px;
}

.login-icons{
    margin: 10px;
    font-size: 30px;
}

.card-view-login{
    border-radius: 10px;
    padding: 5% 20%;
    min-height: 70vh;
}

.login-img{
    width: auto;
    max-height: 70vh;
}

