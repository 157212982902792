.carousel-image {
    max-height: 90vh;
    min-height: 60vh;
}

.zoom {
    animation: zoom 20s infinite;
}

@keyframes zoom {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}



.carousel{
    z-index: 1;
}

.search-bar-heading {
    margin-bottom: 20px;
}

.top-search-bar-container {
    padding: 20px;
    background-color: #434343;
    border: 1px solid #434343;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    width: 75%;
    margin-top: -25%;
    z-index: 99;
    position: absolute;
    margin-left: 12%;
    opacity: 75%;
}

.top-search-bar-container:hover {
    opacity: 100%;
}

.top-search-bar-container::before {
    opacity: 100%;
}

.form-control-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-down-fill' viewBox='0 0 16 16'><path d='M7.247 11.14 2.451 5.658c-.566-.634-.106-1.658.754-1.658h9.788c.86 0 1.32 1.024.754 1.658l-4.796 5.482a1 1 0 0 1-1.508 0z'/></svg>") no-repeat right 0.75rem center/8px 10px;
    padding-right: 1.5rem;
}

.carousel-card {
    margin: 10px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.carousel-card:hover {
    transform: scale(1.05);
}

.slick-slide>div {
    margin: 0 15px;
}

.machinery-img {
    transition: transform 0.3s ease;
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.slider-container .slick-prev:before,
.slider-container .slick-next:before {
    color: #000000;
}

.carousel-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    overflow: hidden;
}

.card {
    margin: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-color: #333;
    /* Customize control button background */
}